import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { Circle, Home, Settings, User2, type LucideIcon } from 'lucide-react'
import { LinkProps } from 'next/link'

export type Link = {
  title: string
  label?: string
  icon: LucideIcon
  href: LinkProps<'a'>['href'] // <"a"> va porque sí
}
// TODO: ver íconos
export const adminLinks: Link[] = [
  {
    href: '/',
    title: 'Home',
    icon: Home,
  },
  {
    href: '/login',
    title: 'Login',
    icon: User2,
  },
  {
    href: '/admin/main-carrousel',
    title: 'Carrousel principal',
    icon: QuestionMarkCircledIcon as LucideIcon,
  },
  {
    title: 'Signup',
    href: '/signup',
    icon: Circle,
  },
  {
    href: '/admin',
    title: 'Admin',
    icon: Circle,
  },
  {
    href: '/ads',
    title: 'Ads',
    icon: Circle,
  },
  {
    href: '/resources',
    title: 'Resources',
    icon: Circle,
  },
  {
    href: '/settings',
    title: 'Settings',
    icon: Settings,
  },
]

export const menu = [
  { href: '/', label: 'Home', alias: 'home' },
  { href: '/pastores#nosotros', label: 'Nosotros', alias: 'pastores' },
  { href: '/pastores#ministerios', label: 'Ministerios', alias: 'ministerios' },
  { href: '/#reuniones', label: 'Reuniones', alias: 'horarios' },
  { href: '/#contacto', label: 'Contacto', alias: 'contacto' },
] as const

export const socialLinks = [
  {
    icon: IconFacebook,
    href: 'https://www.facebook.com/MinisterioAtg',
    label: 'Facebook',
  },
  {
    icon: IconInstagram,
    href: 'https://www.instagram.com/aposentoalto.uy/',
    label: 'Instagram',
  },
  {
    icon: IconYoutube,
    href: 'https://www.youtube.com/@AposentoAlto-vj1sb',
    label: 'Youtube',
  },
] as const

function IconFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.751 17.8755C35.751 8.00852 27.7547 0 17.8755 0C7.99629 0 0 8.00852 0 17.8755C0 26.801 6.54131 34.1982 15.0878 35.5309V23.0352H10.5517V17.8633H15.0878V13.9263C15.0878 9.45127 17.7532 6.96924 21.837 6.96924C23.7932 6.96924 25.8351 7.32382 25.8351 7.32382V11.7254H23.5854C21.3601 11.7254 20.6754 13.1071 20.6754 14.5131V17.8633H25.6395L24.8447 23.0352H20.6754V35.5309C29.2219 34.186 35.7632 26.7888 35.7632 17.8755H35.751Z"
        fill="#CABC9F"
      />
      <path
        d="M24.8326 23.0474L25.6273 17.8755H20.6633V14.5253C20.6633 13.107 21.3602 11.7376 23.5733 11.7376H25.823V7.33602C25.823 7.33602 23.7811 6.98145 21.8248 6.98145C17.7411 6.98145 15.0757 9.45125 15.0757 13.9385V17.8755H10.5396V23.0474H15.0757V35.5431C15.9805 35.6898 16.9219 35.7632 17.8634 35.7632C18.8048 35.7632 19.7463 35.6898 20.6511 35.5431V23.0474H24.8204H24.8326Z"
        fill="black"
      />
    </svg>
  )
}

function IconInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#CABC9F"
        d="M18.639 35.751c9.872 0 17.875-8.003 17.875-17.875C36.514 8.002 28.511 0 18.64 0 8.766 0 .763 8.003.763 17.875c0 9.873 8.003 17.876 17.876 17.876Z"
      />
      <path
        fill="#000"
        d="M24.19 11.114c-.673 0-1.223.55-1.223 1.235s.55 1.235 1.223 1.235a1.24 1.24 0 0 0 1.235-1.235c0-.684-.55-1.235-1.235-1.235ZM18.725 12.692a5.16 5.16 0 0 0 0 10.319 5.16 5.16 0 0 0 0-10.32Zm0 8.473a3.303 3.303 0 0 1-3.302-3.301 3.303 3.303 0 0 1 3.302-3.302 3.303 3.303 0 0 1 3.3 3.302 3.303 3.303 0 0 1-3.3 3.3Z"
      />
      <path
        fill="#000"
        d="M22.833 28.33H14.47a6.303 6.303 0 0 1-6.297-6.297V13.67a6.303 6.303 0 0 1 6.297-6.297h8.363a6.303 6.303 0 0 1 6.297 6.297v8.363a6.304 6.304 0 0 1-6.297 6.297ZM14.47 9.342a4.336 4.336 0 0 0-4.329 4.328v8.363a4.336 4.336 0 0 0 4.329 4.328h8.363a4.336 4.336 0 0 0 4.328-4.328V13.67a4.336 4.336 0 0 0-4.328-4.328H14.47Z"
      />
    </svg>
  )
}

function IconYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#CABC9F"
        d="M18.39 35.751c9.872 0 17.875-8.003 17.875-17.875C36.265 8.002 28.262 0 18.39 0 8.517 0 .514 8.003.514 17.875c0 9.873 8.003 17.876 17.876 17.876Z"
      />
      <path
        fill="#000"
        d="M27.951 15.271a4.29 4.29 0 0 0-4.292-4.291H12.68a4.29 4.29 0 0 0-4.29 4.291v5.111a4.29 4.29 0 0 0 4.29 4.292h10.98a4.29 4.29 0 0 0 4.292-4.292v-5.11Zm-6.456 2.935-4.927 2.433c-.196.11-.844-.037-.844-.257v-5c0-.22.66-.367.856-.257l4.707 2.567c.196.11.404.404.208.502v.012Z"
      />
    </svg>
  )
}
