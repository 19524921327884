import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/app/_components/actividades.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/app/_components/formulario.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/app/_components/horarios.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/app/_components/main-carrousel.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/components/header.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/components/ui/button.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/lib/hooks/useOnScreen.tsx");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/rodrigo/projects/freelance/aposento-alto_web/public/icons/whatsapp.svg")