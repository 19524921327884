'use client'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { cn } from '@/lib/utils'
import alert from '@/public/icons/alert.svg'
import Image from 'next/image'
import Link from 'next/link'
import { useFormState, useFormStatus } from 'react-dom'
import { handleFormSubmit } from '../actions/handleFormSubmit'
import SubmitButton from './submit-button'

const initialState = {
  message: '',
  status: 'idle',
}
export default function Formulario() {
  const { pending } = useFormStatus()
  const [state, formAction] = useFormState(handleFormSubmit, initialState)
  /**

 */
  return (
    <div
      className="flex min-h-screen w-full flex-col items-center p-4 sm:p-24"
      id="Reservar"
    >
      <div className="">
        <div className="relative pt-32 sm:p-0 ">
          <Image
            src="/images/formulario/equipo-pastoral.png"
            alt="whatsapp"
            width={1920}
            height={1080}
            className="w-[740px]"
          />
          <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-t from-white from-15% via-[#ffffff4d] via-30% to-transparent to-45%" />
          <Link
            href='https://www.youtube.com/@AposentoAlto-vj1sb'
            target="_blank"
            className="absolute top-6 sm:top-auto sm:bottom-0 left-1/2 -translate-x-1/2 transform drop-shadow-sm transition-all duration-300 ease-in-out hover:scale-110 hover:drop-shadow-md"
          >
            <Image
              src="/images/formulario/logotipo-youtube.png"
              alt="whatsapp"
              width={294}
              height={123}
              className='w-52 xl:w-72 h-full'
            />
          </Link>
        </div>
        <div className="flex flex-col items-center pb-32 sm:pt-8">
          <p className="flex items-center text-center text-base xs:text-xl font-bold xl:text-2xl">
            <Image src={alert} alt="" width={34} height={34} />
            Suscribite a nuestro Canal
          </p>
          <p className="max-w-[50ch] pt-2 text-center text-sm xl:text-base font-light">
            Disfrutá de nuestras Predicaciones, Estudios Bíblicos, Reuniones en
            VIVO y mucho más.
          </p>
        </div>
      </div>

      <div
        className="mx-auto flex w-full max-w-2xl xl:max-w-3xl flex-col justify-center px-6 sm:px-12 "
        id="contacto"
      >
        <h5 className="text-center font-roboto text-xl xl:text-2xl font-light">
          Estamos para servirte
        </h5>
        <h4 className="mb-4 text-center font-nourd text-3xl xl:text-4xl font-bold text-black pb-8">
          Comunicate con Nosotros
        </h4>
        <form
          action={formAction}
          className="relative space-y-6 font-roboto text-base font-light leading-normal"
        >
          <div>
            <Input name="name" placeholder="Nombre y Apellido" required className='h-16 font-thin text-lg' />
          </div>
          <div className="flex flex-col gap-6 md:gap-2 md:flex-row">
            <Input name="lugar" placeholder="Lugar" required className='h-16 font-thin text-lg' />
            <Input type="email" name="email" placeholder="Email" required className='h-16 font-thin text-lg' />
          </div>
          <div></div>
          <div>
            <Textarea 
              name="message" 
              placeholder="Mensaje" 
              required 
              className='flex h-16 min-h-[128px] font-thin text-lg  w-full rounded-2xl border border-input bg-[#E6E6E6] px-5 py-2 text-black ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
            />
          </div>
          <p aria-live="polite" className="sr-only">
            {state?.status}
          </p>
          <SubmitButton />

          <p
            className={cn(
              'font-inter text-sm ',
              state.status === 'success' ? 'text-green-500' : 'text-red-500'
            )}
          >
            {state?.message}
          </p>
        </form>
      </div>
    </div>
  )
}
