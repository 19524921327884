import { cn } from "@/lib/utils"
import { CSSProperties } from "react"
import { useSwiper } from "swiper/react"

export const ArrowLeft = ({
  className,
  style,
  color,
}: {
  className: string
  style?: CSSProperties
  color?: string
}) => {
  const swiper = useSwiper()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="47"
      fill="none"
      viewBox="0 0 26 47"
      className={cn("cursor-pointer", className)}
      style={style}
      onClick={() => swiper.slidePrev()}
    >
      <path
        // stroke="#000"
        stroke={color || '#000'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M23.5 44 3 23.5 23.5 3"
      />
    </svg>
  )
}

export const ArrowRight = ({
  className,
  onClick,
  style,
  color,
}: {
  className: string
  onClick?: () => void
  style?: CSSProperties
  color?: string
}) => {
  const swiper = useSwiper()
  return (
    <svg
      width="26"
      height="47"
      viewBox="0 0 26 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("cursor-pointer", className)}
      style={style}
      onClick={() => swiper.slideNext()}
    >
      <path
        d="M2.5 44L23 23.5L2.5 3"
        // stroke="#000"
        stroke={color || '#000'}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
