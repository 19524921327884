'use client'
import useScrollDirection from '@/lib/hooks/useScrollDirection'
import React, { RefObject, useEffect, useRef, useState } from 'react'

import { menu, socialLinks } from '@/config/links'
import { useOnScreen } from '@/lib/hooks/useOnScreen'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import ActiveLink from './ui/activelink'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const scrollDirection = useScrollDirection()
  const { isOnScreen } = useOnScreen()
  const menuRef: RefObject<HTMLDivElement> = useRef(null)

  const handleToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleCloseMenu = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuRef.current &&
        isOpen &&
        !menuRef.current.contains(event.target as Node)
      ) {
        handleCloseMenu()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])
  return (
    <header
      className={cn(
        'fixed z-50 flex h-[78px] w-full justify-center bg-black/90 bg-gradient-to-b to-transparent transition-all duration-500 ease-in-out',
        {
          'fixed top-0': scrollDirection === 'up',
          'fixed top-[-78px]': scrollDirection === 'down',
          'bg-black/80': isOnScreen,
        }
      )}
    >
      <div className="flex w-full max-w-7xl items-center justify-between gap-2 px-12 ">
        <Link href={'/'}>
          <Image
            width={42}
            height={42}
            src={'/images/logo.svg'}
            alt="Logo"
            className="transition-all duration-300 ease-in-out hover:scale-110"
          />
        </Link>
        <div className="hidden items-center justify-center gap-8 font-inter text-xs text-white md:flex lg:gap-8 xl:text-base  ">
          {menu.map((link, index) => (
            <ActiveLink
              key={index}
              href={link.href}
              className="hover:text-[#B7A16D]"
              activeClassName="text-[#B7A16D] font-bold"
            >
              {link.label}
            </ActiveLink>
          ))}
        </div>
        <div className="flex items-center gap-4">
          <div className="flex gap-3 md:gap-2 ">
            {socialLinks.map((link, index) => (
              <Link
                key={index}
                href={link.href}
                target="_blank"
                aria-label={link.label}
                className="transition-all duration-300 ease-in-out hover:scale-110"
                // className="h-9 w-9  md:h-7 md:w-7"
              >
                <link.icon className="size-7 md:size-6" />
              </Link>
            ))}
          </div>
          <Image
            width={48}
            height={48}
            src={'/icons/menu.svg'}
            className="color-white h-9  w-9 cursor-pointer md:size-12"
            onClick={handleToggleMenu}
            alt="Menu"
          />
        </div>
      </div>
      {/* Sidebar */}
      <div
        className={cn(
          'fixed left-0 top-0 z-10 flex h-full w-screen transform  flex-col  bg-[#262626] bg-opacity-[0.97] pl-12 pt-24 text-white transition-transform  ',
          {
            '-translate-x-[100vw]': !isOpen,
          }
        )}
        ref={menuRef}
      >
        <div className="flex items-center justify-between  pb-8">
          <Image width={67} height={82} src={'/images/logo.svg'} alt="Logo" />

          <button onClick={handleCloseMenu} className="pr-16">
            <IconXmark className="h-8 w-8" />
          </button>
        </div>
        <div className="flex flex-col items-center gap-12">
          <div className="mb-8 flex flex-col gap-8 text-center  text-2xl font-medium text-white">
            {menu.map((link, index) => (
              <ActiveLink
                key={index}
                href={link.href}
                onClick={handleCloseMenu}
                className="text-4xl font-medium"
                activeClassName="text-[#CBBB9D] font-black text-4xl  border-b-2 border-[#CBBB9D]"
              >
                {link.label}
              </ActiveLink>
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}
function IconXmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}
export default Header
