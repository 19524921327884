import { Button } from '@/components/ui/button'
import { useFormStatus } from 'react-dom'

export default function SubmitButton() {
  const { pending } = useFormStatus()

  return (
    <Button
      type="submit"
      className="rounded-xl font-semibold hover:border-black hover:bg-black"
      size={'lg'}
      aria-disabled={pending}
      disabled={pending}
    >
      {pending ? 'Enviando...' : 'Enviar'}
    </Button>
  )
}
