'use client'
import { useOnScreen } from '@/lib/hooks/useOnScreen'
import logo from '@/public/images/logo-completo.svg'
import Image from 'next/image'
import { Autoplay, Navigation, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ArrowLeft, ArrowRight } from '@/components/swiper-arrows'
import 'swiper/css'
import 'swiper/css/effect-fade';


import { cn } from '@/lib/utils'

// object-bottom object-center object-left object-left-bottom object-right object-right-bottom object-right-top object-top
const images = [
  {
    img: '/images/main-slider/01-slider-hero.jpg',
    position: 'center',
  },
  {
    img: '/images/main-slider/02-slider-hero.png',
    position: '80% 50%',
  },
]
export default function MainCarrousel() {
  const { ref } = useOnScreen()

  return (
    <>
      <div className="relative h-screen" ref={ref}>
        <Swiper
          modules={[Autoplay, Navigation, EffectFade]}
          navigation={{
            nextEl: '.swiper-button-next-main',
            prevEl: '.swiper-button-prev-main',
          }}
          effect="fade"
          loop={true}
          speed={1000}
          autoplay={{
            delay: 3000,
            // disableOnInteraction: false,
            // pauseOnMouseEnter: true,
          }}
        >
          <div className="absolute bottom-0 left-0  z-10 h-full w-full bg-gradient-to-t from-black from-0% via-black via-5% md:via-0% to-[#0f0f1000] to-65%" />
          <div className="min-w-screen absolute top-0 z-20 flex h-screen min-h-screen w-screen flex-col items-center justify-end pb-6">
            <div className="flex w-full flex-col items-center justify-center gap-4 md:gap-8 p-2 md:p-6 md:flex-row ">
              <Image
                src={logo}
                alt="Logo"
                width={250}
                height={90}
                className="h-16  md:h-[70px]"
                priority
              />
              <Image
                width={220}
                height={55}
                className="h-10 md:h-[40px] "
                src={'/icons/iglesia.svg'}
                alt="Logo"
              />
            </div>

            <span className="px-4 text-center	text-lg font-light tracking-widest text-white">
              Uruguay, Montevideo | Hungría 3686
            </span>
          </div>
          {images.map((image, index) => {
            return (
              <SwiperSlide key={image.img}>
                <div className="h-screen w-screen">
                  <Image
                    src={image.img}
                    alt=""
                    loading="eager"
                    fill
                    style={{
                      objectPosition: image.position,
                    }}
                    className={cn(
                      ' -z-10 h-screen w-screen object-cover'
                    )}
                  />
                </div>
              </SwiperSlide>
            )
          })}
          <ArrowLeft
            color="#CBBB9D"
            className="absolute left-24 top-1/2 z-20 hidden  w-10  -translate-y-1/2  md:block"
          />
          <ArrowRight
            color="#CBBB9D"
            className="absolute right-24 top-1/2 z-20 hidden  w-10  -translate-y-1/2  md:block"
          />
        </Swiper>
      </div>
      <div className="hidden bg-black py-7 px-4 text-center md:flex  md:flex-col md:items-center md:gap-2">
        <span className="font-nourd text-3xl font-bold text-white ">
          Sonia y Asdrubal Martinez
        </span>
        <span className="font-nourd text-2xl font-medium text-[#CABC9F]">
          Pastores Principales
        </span>
      </div>
    </>
  )
}
