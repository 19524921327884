'use client'
import { cn } from '@/lib/utils'
import Link, { LinkProps } from 'next/link'
import { usePathname } from 'next/navigation'

interface ActiveLinkProps extends LinkProps {
  onOpenChange?: (open: boolean) => void
  children: React.ReactNode
  className?: string
  activeClassName?: string | ((isActive: boolean) => string)
}

export default function ActiveLink({
  children,
  className,
  activeClassName,
  onOpenChange,
  ...rest
}: ActiveLinkProps) {
  const { href } = rest
  const pathName = usePathname()
  const isActive = pathName === href

  let activeClass = ''
  if (typeof activeClassName === 'function') {
    activeClass = activeClassName(isActive)
  } else if (isActive) {
    activeClass = activeClassName || ''
  }

  return (
    <Link {...rest} className={cn(className, activeClass)}>
      {children}
    </Link>
  )
}
