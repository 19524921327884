'use client'
import React, {
  MutableRefObject,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react'

interface OnScreenContextProps {
  isOnScreen: boolean
  ref: MutableRefObject<null>
  setThreshold: React.Dispatch<React.SetStateAction<number>>
}
const CurrentOnScreenContext = createContext<OnScreenContextProps | null>(null)

export const useOnScreen = () => {
  const context = React.useContext(CurrentOnScreenContext)
  if (!context) {
    throw new Error(`OnScreenContext must be used within a OnScreenProvider`)
  }
  return context
}

function useOnScreenHook(
  ref: MutableRefObject<null>,
  threshold: number = 0
): boolean {
  const [isOnScreen, setOnScreen] = useState<boolean>(false)

  useEffect(() => {
    const currentRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setOnScreen(entry?.isIntersecting!)
      },
      {
        rootMargin: '0px',
        threshold: threshold,
      }
    )
    if (currentRef) {
      observer.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [ref, threshold])

  return isOnScreen
}

export default function OnScreenProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const ref = useRef<null>(null)
  const [threshold, setThreshold] = useState(0)
  const isOnScreen = useOnScreenHook(ref, threshold)

  return (
    <CurrentOnScreenContext.Provider value={{ isOnScreen, ref, setThreshold }}>
      {children}
    </CurrentOnScreenContext.Provider>
  )
}
