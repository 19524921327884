'use client'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import { CSSProperties, useState } from 'react'
import { A11y, Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import Lightbox from 'yet-another-react-lightbox'


import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/styles.css'

const actividades = [
  {
    title: 'Jóvenes',
    images: [
      '/images/jovenes/01-jovenes.jpg',
      '/images/jovenes/02-jovenes.jpg',
      '/images/jovenes/03-jovenes.jpg',
      '/images/jovenes/04-jovenes.jpg',
      '/images/jovenes/05-jovenes.jpg',
      '/images/jovenes/06-jovenes.jpg',
      '/images/jovenes/07-jovenes.jpg',
      '/images/jovenes/08-jovenes.jpg',
      '/images/jovenes/09-jovenes.jpg',
      '/images/jovenes/10-jovenes.jpg',
    ],
  },
  {
    title: 'Damas',
    images: [
      '/images/damas/01-damas.jpg',
      '/images/damas/02-damas.jpg',
      '/images/damas/03-damas.jpg',
      '/images/damas/04-damas.jpg',
      '/images/damas/05-damas.jpg',
      '/images/damas/06-damas.jpg',
      '/images/damas/07-damas.jpg',
      '/images/damas/08-damas.jpg',
      '/images/damas/09-damas.jpg',
      '/images/damas/10-damas.jpg',
    ],
  },
  {
    title: 'Escuela de vida',
    images: [
      // '/images/reunion/02-reunion.jpg',
      // '/images/reunion/01-reunion.jpg',
      // '/images/reunion/03-reunion.jpg',
      // '/images/reunion/04-reunion.jpg',
      // '/images/reunion/05-reunion.jpg',
      // '/images/reunion/06-reunion.jpg',
      // '/images/reunion/07-reunion.jpg',
      // '/images/reunion/08-reunion.jpg',
      // '/images/reunion/09-reunion.jpg',
      // '/images/reunion/10-reunion.jpg',
      '/images/graduacion/01-graduacion.jpg',
      '/images/graduacion/02-graduacion.jpg',
      '/images/graduacion/03-graduacion.jpg',
      '/images/graduacion/04-graduacion.jpg',
      '/images/graduacion/05-graduacion.jpg',
      '/images/graduacion/06-graduacion.jpg',
      '/images/graduacion/07-graduacion.jpg',
      '/images/graduacion/08-graduacion.jpg',
      '/images/graduacion/09-graduacion.jpg',
      '/images/graduacion/10-graduacion.jpg',
    ],
  },
  {
    title: 'Niños',
    images: [
      '/images/niños/01-niños.jpg',
      '/images/niños/02-niños.jpg',
      '/images/niños/03-niños.jpg',
      '/images/niños/04-niños.jpg',
      '/images/niños/05-niños.jpg',
      '/images/niños/06-niños.jpg',
      '/images/niños/07-niños.jpg',
      '/images/niños/08-niños.jpg',
      '/images/niños/09-niños.jpg',
      '/images/niños/10-niños.jpg',
    ],
  },
]

export default function Actividades() {
  const [galeria, setGaleria] = useState<string[]>([])
  return (
    <div className="flex  w-full flex-col items-center bg-black pb-32">
      <div className="grid place-items-center p-2 pb-12 text-center md:p-16">
        <h4 className="max-w-[10cm] text-2xl text-[#CBBB9D] md:max-w-none md:text-base">
          Algunas de nuestras actividades
        </h4>
        <h2 className="max-w-[15ch] text-4xl font-semibold text-white md:max-w-none">
          Dios Anhela que seas parte
        </h2>
      </div>

      <div className="hidden h-full max-w-4xl gap-6 px-8 md:flex xl:max-w-none">
        {actividades.map((act, index) => (
          <Card
            key={index}
            title={act.title}
            img={act.images[0] || ""}
            alt=""
            down={index % 2 === 0}
            onClick={() => setGaleria(act.images)}
          />
        ))}
      </div>
      <div className="relative h-full w-full bg-black md:hidden">
        <Swiper
          modules={[Navigation, A11y, Autoplay]}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          speed={600}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          className="relative h-full w-full "
        >
          {actividades.map((act, index) => (
            <SwiperSlide key={index}>
              <div className="relative flex h-full items-center justify-center px-4 pb-32 ">
                <Card
                  title={act.title}
                  img={act.images[0] || ""}
                  alt=""
                  down
                  isSwiperCard
                  onClick={() => setGaleria(act.images)}
                />
              </div>
            </SwiperSlide>
          ))}
          <ArrowLeft
            className=" absolute bottom-5 left-[44%] z-20 w-6 -translate-y-1/2 transform active:stroke-[#CBBB9D] hover:stroke-[#CBBB9D]"
          />
          <ArrowRight
            className="absolute bottom-5 right-[44%] z-20 w-6 -translate-y-1/2 transform hover:stroke-[#CBBB9D] active:stroke-[#CBBB9D]"
          />
        </Swiper>
      </div>

      <Lightbox
        open={galeria.length > 0}
        close={() => setGaleria([])}
        
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        slides={[
          ...galeria.map((img) => ({
            src: img,
          })),
        ]}
        styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .8)' } }}
      />
    </div>
  )
}

const Card = ({
  title,
  img,
  alt,
  down,
  isSwiperCard,
  onClick,
}: {
  title: string
  img: string
  alt: string
  down?: boolean
  isSwiperCard?: boolean
  onClick?: () => void
}) => {
  return (
    <div
      className={cn(
        'group cursor-pointer relative',
        down && 'mt-8',
        isSwiperCard && 'w-full'
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'relative h-[85vh]  xl:w-[230px] overflow-hidden  md:h-[300px] xl:h-[350px]',
          isSwiperCard && 'w-full'
        )}
      >
        <Image
          src={img}
          alt={alt}
          // fill
          width={1080}
          height={1920}
          className={cn(
            'h-full transform object-cover transition-transform group-hover:scale-110',
            isSwiperCard && 'w-full'
          )}
        />
        <div className="absolute bottom-0 flex h-1/2 w-full items-end justify-center bg-gradient-to-t from-black via-black via-20% to-transparent text-center text-white">
          <p
            className={
              'z-20 max-w-[8ch]  text-xl font-semibold uppercase text-white'
            }
          >
            {title}
          </p>
        </div>
      </div>
    </div>
  )
}





const ArrowLeft = ({
  className,
  style,
}: {
  className: string
  style?: CSSProperties
}) => {
  const swiper = useSwiper()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="47"
      fill="none"
      viewBox="0 0 26 47"
      className={cn("stroke-white",className)}
      style={style}
      onClick={() => swiper.slidePrev()}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M23.5 44 3 23.5 23.5 3"
      />
    </svg>
  )
}

const ArrowRight = ({
  className,
  onClick,
  style,
}: {
  className: string
  onClick?: () => void
  style?: CSSProperties
}) => {
  const swiper = useSwiper()
  return (
    <svg
      width="26"
      height="47"
      viewBox="0 0 26 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("stroke-white",className)}
      style={style}
      onClick={() => swiper.slideNext()}
    >
      <path
        d="M2.5 44L23 23.5L2.5 3"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
