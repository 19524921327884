'use client'
import logo from '@/public/images/logo.svg'
import Image from 'next/image'
import { CSSProperties } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { A11y, Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

const horarios = [
  {
    title: 'Estudio de la Palabra',
    time: 'Miércoles 19:00hs.',
  },
  {
    title: 'Reunión de Oración',
    time: 'Viernes 19:00hs.',
  },
  {
    title: 'Reunión General',
    time: 'Domingo 17:00hs.',
  },
]

export default function Horarios() {
  return (
    <div
      className="relative flex min-h-screen w-full flex-col items-center justify-end bg-black"
      id="reuniones"
    >
      <Image
        src={'/images/01-home_reuniones.jpeg'}
        alt=""
        loading="eager"
        fill
        className=" z-10 h-screen w-screen object-cover opacity-50"
      />
      <div className="relative z-20 flex flex-col items-center py-6">
        <Image src={logo} alt="Logo" width={110} height={67} className="lg:pb-8" />
        <div className="hidden gap-2 sm:flex lg:gap-8">
          {horarios.map((h, i) => (
            <div
              key={i}
              className="relative flex h-52 w-[200px] max-w-96 flex-col items-center justify-center p-2 text-center md:w-[250px] lg:w-[300px] "
            >
              <BorderSvg className="absolute  w-full" />
              <div className='lg:pb-8'>
                <h5 className=" font-nourd font-semibold text-[#CBBB9D] md:text-xl lg:text-2xl">
                  {h.title}
                </h5>
                <p className="font-nourd text-white md:text-xl lg:text-2xl">
                  {h.time}
                </p>

              </div>
            </div>
          ))}
        </div>
        <div className="relative h-64 w-full sm:hidden ">
          <Swiper
            modules={[Navigation, A11y, Autoplay]}
            slidesPerView={1}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={600}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            className="relative h-full w-[300px] "
          >
            {horarios.map((h, index) => (
              <SwiperSlide key={index} className="relative w-full">
                <div className="relative flex h-52 max-w-96 flex-col items-center justify-center  p-2 text-center md:p-4 lg:p-12 ">
                  <BorderSvg className="z-100 absolute w-full " />
                  <h5 className=" text-xl font-semibold text-[#CBBB9D] lg:text-2xl">
                    {h.title}
                  </h5>
                  <p className="text-xl text-white lg:text-2xl">{h.time}</p>
                </div>
              </SwiperSlide>
            ))}

            <ArrowLeft
              color="white"
              className=" absolute bottom-5 left-[30%] z-20 w-6 -translate-y-1/2 transform"
            />
            <ArrowRight
              color="white"
              className="absolute bottom-5 right-[30%] z-20 w-6 -translate-y-1/2 transform"
            />
          </Swiper>
        </div>
        <p className="max-w-[55ch] px-4 text-center font-inter text-sm text-white sm:text-base  xl:text-xl">
          Sábados una vez al mes, Reunión General de los Ministerios.
        </p>
        <p className="max-w-[40ch] px-4 text-center font-inter text-sm text-white sm:text-base  xl:text-xl md:pb-6">
          {' '}
          + Info en nuestras Redes Sociales
        </p>
      </div>
    </div>
  )
}
const BorderSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="374"
      // height="204"
      fill="none"
      viewBox="0 0 374 204"
      className={className}
    >
      <rect
        width="372"
        height="202"
        x="1"
        y="1"
        stroke="url(#a)"
        strokeWidth="2"
        rx="31"
      />
      <defs>
        <linearGradient
          id="a"
          x1="187"
          x2="187"
          y1="0"
          y2="204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".885" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const ArrowLeft = ({
  className,
  style,
  color,
}: {
  className: string
  style?: CSSProperties
  color?: string
}) => {
  const swiper = useSwiper()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="47"
      fill="none"
      viewBox="0 0 26 47"
      className={className}
      style={style}
      onClick={() => swiper.slidePrev()}
    >
      <path
        // stroke="#000"
        stroke={color || '#000'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M23.5 44 3 23.5 23.5 3"
      />
    </svg>
  )
}

const ArrowRight = ({
  className,
  onClick,
  style,
  color,
}: {
  className: string
  onClick?: () => void
  style?: CSSProperties
  color?: string
}) => {
  const swiper = useSwiper()
  return (
    <svg
      width="26"
      height="47"
      viewBox="0 0 26 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={() => swiper.slideNext()}
    >
      <path
        d="M2.5 44L23 23.5L2.5 3"
        // stroke="#000"
        stroke={color || '#000'}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
